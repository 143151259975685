import React from 'react'
import '../new_component/more_read.css'
import princiapl_image from '../new_component/prinicipal_image.jpg'
import Accordian from '../new/footer'
export default function Read_more_massage() {
    return (
       <>
       <div className="row-preginations">
        <div className="col-preginations">
            <div className="imagebox">
                <img src="" alt="" />
            </div>
            
        </div>
        <div className="col-preginations">
            <div className="details">
                

            </div>
        </div>
       </div>
       </>
    )
}
