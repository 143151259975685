import React from 'react'
import '../developer/per.css'
import Accordian from '../new/footer'
export default function Personal_port() {
    return (

        <>

            <header>
                <img src="https://scontent.fmey2-1.fna.fbcdn.net/v/t39.30808-6/387214772_1367826760471493_7823846070529353045_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Kkz2u-2lJmUAX_d6L-2&_nc_oc=Adh1voedQPDzyzLYV2zTim3pqEwIYPyRPwqHni73c5SKU3aR0TpdoLp3Yrk7qaAg10c&_nc_ht=scontent.fmey2-1.fna&oh=00_AfAck53AU2oknyrd8TW1LMachwYeKMJAysdG9vBeYFR2CQ&oe=6603EFCA" className='mm'></img>
                <h1>Chandan Sharmaa</h1>
                <p>Full Stack Developer // java developer //mern developer with next js</p>
            </header>


            <Accordian />


        </>
    )
}
