
export default function Plan() {

    // const [numpages, setNumPages] = useState(null)
    // const [pageNumber, setPageNumber] = useState(1);

    // const onDocumentSuccess = () => {
    //     setNumPages(numpages)
    // }

    // const prevPage = () => {
    //     setPageNumber(pageNumber <= 1 ? 1 : pageNumber - 1);
    // }

    // const nextPage = () => {
    //     setPageNumber(pageNumber >= numpages ? pageNumber : pageNumber + 1);
    // }
    // return (
    //     <div>
    //         <div className='d-flex align-items-center justify-content-center'>
    //             <div className="">
    //                 <i className='bi bi-arrow-left-square-fill display-5 text-danger pointer' role='button' onClick={prevPage} ></i>
    //                 <i className='bi bi-arrow-right-square-fill display-5 text-success' role='button' onClick={nextPage} ></i>
    //             </div>
    //             <Document file={filepdf} onLoadSuccess={onDocumentSuccess} >
    //                 <Page pageNumber={pageNumber}>

    //                 </Page>

    //             </Document>
    //         </div>
    //     </div>
    // )
}